// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url_authentication: 'https://authentication.inmobilio.co/',
  url_estate: 'https://bienesraices.inmobilio.co/',
  url_publications: 'https://publicaciones.inmobilio.co/',
  url_interested: 'https://interesados.inmobilio.co/',
  url_appointment: 'https://visitas.inmobilio.co/',
  url_emails: 'https://mails.inmobilio.co',
  image_key: '837abee8-1474-44d7-97d0-0260b19c66a5=true',
  // for request .json file
  local_url: '//localhost:4200'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
